<template>
  <div class="modalPopup package_modal">
    <div class="modal_header">
      <h3 class="title">
        포장 및 제품생산 결과
      </h3>
      <button
        type="button"
        class="modal_close"
        @click="$emit('onClose')"
      ></button>
    </div>

    <div class="modal_body">
      <div class="modal_search_opt">
        <div class="input_text">
          <label>제품명</label>
          <input type="text" readonly :value="target_product.name" />
        </div>
        <div class="input_text">
          <label>규격</label>
          <input type="text" readonly :value="target_product.standard" />
        </div>
        <div class="input_text">
          <label>총 중량</label>
          <input
            :class="{ max: totalQuantity > maxQuantity }"
            type="text"
            readonly
            :value="`${$makeComma(totalQuantity)} / ${$makeComma(maxQuantity)}`"
          />
          <label>{{
            findInfoFromId(units, target_product.stock_unit_id).name
          }}</label>
        </div>
        <h5>생산가능 제품</h5>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>제품</th>
              <th>규격</th>
              <th>투입량</th>
              <th>지시수량</th>
              <th>합격수량</th>
              <th>불량수량</th>
              <th>투입총량</th>
              <th v-if="getCounterSensor.all.length > 0">
                설비연동
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in member_product_list" :key="item.id">
              <td class="text_left">
                {{ findInfoFromId(products, item.member_product_id).name }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(products, item.member_product_id).standard }}
              </td>
              <td class="text_right">
                {{
                  systemCompany.use_crushed_yn && item.resource_type_id == 6
                    ? ''
                    : `${$makeComma(item.quantity)} ${
                        findInfoFromId(units, target_product.stock_unit_id).name
                      }`
                }}
              </td>
              <td class="text_right">
                <b>{{
                  systemCompany.use_crushed_yn && item.resource_type_id == 6
                    ? ''
                    : item.detail_quantity
                }}</b
                >{{
                  systemCompany.use_crushed_yn && item.resource_type_id == 6
                    ? ''
                    : ` ${
                        findInfoFromId(
                          units,
                          findInfoFromId(products, item.member_product_id)
                            .stock_unit_id,
                        ).name
                      }`
                }}
              </td>
              <td class="text_right">
                <input
                  :class="{ pass_input: item.sync_plc }"
                  type="text"
                  :value="$makeComma(item.pass_quantity)"
                  inputmode="decimal"
                  @input="
                    $inputNumber($event, item, 'pass_quantity');
                    makeCrushedQuantity(item);
                  "
                  :readonly="
                    item.sync_plc ||
                      (systemCompany.use_crushed_yn &&
                        item.resource_type_id == 6)
                  "
                  :disabled="
                    item.sync_plc ||
                      (systemCompany.use_crushed_yn &&
                        item.resource_type_id == 6)
                  "
                />
              </td>
              <td class="text_right">
                <input
                  class="bad_input"
                  type="text"
                  :value="$makeComma(item.fail_quantity)"
                  inputmode="decimal"
                  @input="$inputNumber($event, item, 'fail_quantity')"
                  :readonly="
                    item.sync_plc ||
                      (systemCompany.use_crushed_yn &&
                        item.resource_type_id == 6)
                  "
                  :disabled="
                    item.sync_plc ||
                      (systemCompany.use_crushed_yn &&
                        item.resource_type_id == 6)
                  "
                />
              </td>
              <td class="text_right">
                <b>{{
                  $makeComma(
                    $decimalMul(
                      $makeNumber(item.quantity),
                      $decimalAdd(
                        $makeNumber(item.pass_quantity),
                        $makeNumber(item.fail_quantity),
                      ),
                    ),
                  )
                }}</b
                >{{
                  systemCompany.use_crushed_yn && item.resource_type_id == 6
                    ? findInfoFromId(
                        units,
                        findInfoFromId(products, item.member_product_id)
                          .stock_unit_id,
                      ).name
                    : findInfoFromId(units, target_product.stock_unit_id).name
                }}
              </td>
              <td v-if="getCounterSensor.all.length > 0">
                <button
                  class="btn_toggle"
                  :class="{ off: !item.sync_plc }"
                  :disabled="isSyncPlcLeastOne && !item.sync_plc"
                  @click="
                    rowTemp = item;
                    toggleReadCounter(item);
                  "
                >
                  <i class="fa fa-check"></i>
                  <i
                    class="fa fa-times"
                    :class="{ wait: !isSyncPlcLeastOne }"
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal_footer">
      <div class="warning">
        {{
          isSyncPlcLeastOne
            ? `'${getCounterSensor.all
                .map(x => x.plc_sensor_name)
                .join(
                  ' / ',
                )}' 하고 연동되고 있습니다. 이 창에서 벗어나지마십시오.`
            : ''
        }}
      </div>
      <button
        type="button"
        class="btn_sub2"
        @click="submitForm"
        :disabled="!isValidConfirm"
      >
        완료
      </button>
    </div>
    <two-button-modal
      :modal_title="'경고'"
      :modal_content="
        '현재 설비의 측정 값이 0보다 큽니다. 그래도 진행하시겠습니까?'
      "
      :isModalOpen="showTwoButtonModal"
      @onclose="showTwoButtonModal = false"
      @OnYesClick="
        showTwoButtonModal = false;
        toggleReadCounter2(rowTemp);
      "
    ></two-button-modal>
    <three-button-modal
      :modal_title="'수량(중량)카운터 초기화여부'"
      :modal_content="
        getCounterSensor.all.filter(x => x.value > 0).length > 0
          ? `연동하려는 설비 <br />'${getCounterSensor.all
              .filter(x => x.value > 0)
              .map(x => x.plc_sensor_name)
              .join(
                ',<br />',
              )}'<br />의 카운터 값이 '0' 이상입니다.<br/><br/> 값을 '0'으로 초기화하고<br/>진행하시겠습니까? `
          : ''
      "
      :show_modal="showThreeButtonModal"
      @onYes="
        resetCounter();

        showThreeButtonModal = false;
      "
      @onNo="
        toggleReadCounter2(rowTemp);
        showThreeButtonModal = false;
      "
      @onClose="showThreeButtonModal = false"
    ></three-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import SPINNER_MIXIN from '@/mixins/spinner';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import ThreeButtonModal from '@/layouts/components/ThreeButtonModal.vue';
export default {
  mixins: [FETCH_MIXIN, MODAL_MIXIN, SPINNER_MIXIN],
  props: {
    packageInfo: {
      type: Object,
      require: true,
    },
    process_id: {
      type: Number,
      require: true,
    },
    lot_id: {
      type: Number,
      require: true,
    },
  },
  components: {
    TwoButtonModal,
    ThreeButtonModal,
  },
  data() {
    return {
      rowTemp: null,
      showTwoButtonModal: false,
      member_product_list: [],
      counter_interval: null,
      timer: 1,
      sensor_list: null,

      resetTime: null,
      showThreeButtonModal: false,
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      lots: 'getLot',
      sales: 'getSales',
      units: 'getUnitCodes',
      sensor_detail_type: 'getSensorDetailTypes',
      process: 'getProcess',
      machine: 'getMachines',
      boxs: 'getBoxsNotSorted',
      plc_sensors: 'getPlcSensor',
      systemCompany: 'getSystemCompany',
    }),
    /**
     * 현재 진행하는 공정의 plc 계수기 센서 Object를 불러오는 compute func
     */
    getCounterSensor() {
      if (this.sensor_list == undefined) {
        return { all: [], pass_counter: [], fail_counter: [] };
      } else {
        const process_id = this.process_id;
        const now_process = this.lodash.clonedeep(
          this.findInfoFromId(this.process, process_id),
        );
        if (now_process == undefined) {
          return { all: [], pass_counter: [], fail_counter: [] };
        } else if (now_process.machine_id == null) {
          return { all: [], pass_counter: [], fail_counter: [] };
        } else {
          const found_machine = this.lodash.clonedeep(
            this.findInfoFromId(this.machine, now_process.machine_id),
          );
          if (found_machine == undefined) {
            return { all: [], pass_counter: [], fail_counter: [] };
          } else {
            const passCounterMachine = this.lodash
              .clonedeep(found_machine.counter_value)
              .filter(x => !x.poor_counter_yn)
              .map(x => x.counter_id);
            const failCounterMachine = this.lodash
              .clonedeep(found_machine.counter_value)
              .filter(x => x.poor_counter_yn)
              .map(x => x.counter_id);
            const passCountSensors = this.lodash
              .clonedeep(this.sensor_list)
              .filter(x => passCounterMachine.includes(x.plc_sensor_id));
            const failCountSensors = this.lodash
              .clonedeep(this.sensor_list)
              .filter(x => failCounterMachine.includes(x.plc_sensor_id));
            return {
              all: passCountSensors.concat(failCountSensors),
              pass_counter: passCountSensors,
              fail_counter: failCountSensors,
            };
          }
        }
      }
    },
    maxQuantity() {
      const passQuntity = this.packageInfo.seq.pass_quantity;

      const failQuntity = this.packageInfo.seq.fail_quntity;
      const sumQuantity = this.$decimalAdd(
        this.$makeNumber(passQuntity),
        this.$makeNumber(failQuntity),
      );
      return sumQuantity;
    },

    totalQuantity() {
      if (this.member_product_list == null) {
        return 0;
      } else {
        return this.member_product_list
          .filter(
            x =>
              this.findInfoFromId(this.products, x.member_product_id)
                .resource_type_id != 6,
          )
          .map(x =>
            this.$decimalMul(
              this.$makeNumber(x.quantity),
              this.$decimalAdd(
                this.$makeNumber(x.pass_quantity),
                this.$makeNumber(x.fail_quantity),
              ),
            ),
          )
          .reduce((a, b) => this.$decimalAdd(a, b), 0);
      }
    },
    totalPassQuantity() {
      if (this.member_product_list == null) {
        return 0;
      } else {
        return this.member_product_list
          .filter(
            x =>
              this.findInfoFromId(this.products, x.member_product_id)
                .resource_type_id != 6,
          )
          .map(x =>
            this.$decimalMul(
              this.$makeNumber(x.quantity),
              this.$makeNumber(x.pass_quantity),
            ),
          )
          .reduce((a, b) => this.$decimalAdd(a, b), 0);
      }
    },
    totalFailQuantity() {
      if (this.member_product_list == null) {
        return 0;
      }
      return this.member_product_list
        .filter(
          x =>
            this.findInfoFromId(this.products, x.member_product_id)
              .resource_type_id != 6,
        )
        .map(x =>
          this.$decimalMul(
            this.$makeNumber(x.quantity),
            this.$makeNumber(x.fail_quantity),
          ),
        )
        .reduce((a, b) => this.$decimalAdd(a, b), 0);
    },
    target_product() {
      return this.lodash.clonedeep(
        this.findInfoFromId(this.products, this.packageInfo.product_id),
      );
    },
    isSyncPlcLeastOne() {
      if (this.member_product_list.filter(x => x.sync_plc).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isValidConfirm() {
      if (
        this.totalQuantity > this.maxQuantity ||
        this.$decimalAdd(
          this.member_product_list
            .map(x => this.$makeNumber(x.pass_quantity))
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
          this.member_product_list
            .map(x => this.$makeNumber(x.fail_quantity))
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
        ) <= 0
      ) {
        return false;
      } else if (this.isSyncPlcLeastOne) {
        return false;
      } else return true;
    },
  },
  methods: {
    async resetCounter() {
      this.showSpinner();
      await this.$store
        .dispatch('RESET_PLC_COUNTER_VALUE', {
          address_list: this.getCounterSensor.all.map(
            x => this.plc_sensors.find(y => y.id == x.plc_sensor_id).address,
          ),
        })
        .then(() => {
          this.resetTime = new Date();
          this.toggleReadCounter2(this.rowTemp);
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('오류', '설비 값 초기화 중 오류 발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async toggleReadCounter(row) {
      if (!row.sync_plc) {
        await this.FETCH_SENSOR_LOG_LSIT_NOW_CHECK();
        if (this.getCounterSensor.all.length > 0) {
          if (this.getCounterSensor.all.findIndex(x => x.value > 0) != -1) {
            this.showThreeButtonModal = true;
          } else {
            row.sync_plc = !row.sync_plc;
            row.start_time = new Date();
            row.end_time = null;
            this.counter_interval = setInterval(() => {
              this.timer -= 1;
            }, 1000);
            this.INSERT_COUNTER_LOG();
            this.changeRowValue();
          }
        } else {
          this.openOneButtonModal(
            '연결 실패',
            '금속검출기와 연결이 끊어졌습니다.',
          );
        }
      } else {
        row.sync_plc = !row.sync_plc;
        row.end_time = new Date();
        clearInterval(this.counter_interval);
        this.UPDATE_COUNTER_LOG();
      }
    },
    async toggleReadCounter2(row) {
      if (!row.sync_plc) {
        row.sync_plc = !row.sync_plc;
        row.start_time = new Date();
        row.end_time = null;
        this.counter_interval = setInterval(() => {
          this.timer -= 1;
        }, 1000);
        this.INSERT_COUNTER_LOG();
        this.changeRowValue();
      } else {
        row.sync_plc = !row.sync_plc;
        row.end_time = new Date();
        clearInterval(this.counter_interval);
        this.UPDATE_COUNTER_LOG();
      }
    },
    makeCrushedQuantity(arg) {
      const findProduct = this.findInfoFromId(
        this.products,
        arg.member_product_id,
      );
      if (findProduct.crushed_product_yn) {
        const findCrushed = this.member_product_list.find(
          x => x.resource_type_id == 6,
        );
        const sprue = findProduct.name.includes('KS-05')
          ? 0.08
          : findProduct.name.includes('K-10')
          ? 0.11
          : 0.1;
        findCrushed.pass_quantity = this.$decimalMul(
          this.$makeNumber(arg.pass_quantity),
          sprue,
        );
      }
    },
    submitForm() {
      const payload = this.lodash.clonedeep(this.packageInfo);
      payload.seq.quantity = this.totalQuantity;
      payload.seq.pass_quantity = this.totalPassQuantity;
      payload.seq.fail_quantity = this.totalFailQuantity;
      let beforePass = 0;
      let beforeFail = 0;
      if (payload.seq_list != undefined) {
        beforePass = payload.seq_list
          .map(x => x.pass_quantity)
          .reduce(
            (a, b) =>
              this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
            0,
          );
        beforeFail = payload.seq_list
          .map(x => x.fail_quantity)
          .reduce(
            (a, b) =>
              this.$decimalAdd(this.$makeNumber(a), this.$makeNumber(b)),
            0,
          );
      }

      payload.quantity = this.totalQuantity;
      payload.pass_quantity = this.$decimalAdd(
        this.$makeNumber(beforePass),
        this.$makeNumber(this.totalPassQuantity),
      );
      payload.fail_quantity = this.$decimalAdd(
        this.$makeNumber(beforeFail),
        this.$makeNumber(this.totalFailQuantity),
      );
      console.log('this.member_product_list', this.member_product_list);
      let package_product_temp = this.lodash.clonedeep(
        this.member_product_list,
      );
      package_product_temp = package_product_temp.map(x => {
        x.pass_quantity = this.$makeNumber(x.pass_quantity);
        x.fail_quantity = this.$makeNumber(x.fail_quantity);
        return x;
      });

      package_product_temp = package_product_temp.filter(
        x => x.pass_quantity > 0 || x.fail_quantity > 0,
      );

      console.log('package_product_temp', package_product_temp);

      if (
        package_product_temp.length == 0 ||
        package_product_temp
          .map(x => x.quantity)
          .reduce((a, b) => this.$decimalAdd(a, b), 0) == 0
      ) {
        payload.pass_quantity = this.maxQuantity;
        payload.quantity = this.maxQuantity;
        payload.fail_quantity = 0;
        payload.seq.pass_quantity = this.maxQuantity;
        payload.seq.quantity = this.maxQuantity;
        payload.seq.fail_quantity = 0;
      }
      payload.package_products = package_product_temp;

      console.log('payload.package_products', payload.package_products);
      console.log('submit', payload);

      payload.package_products = payload.package_products.filter(
        x => x.pass_quantity > 0,
      );

      this.$emit('onSubmit', payload);
    },
    async INSERT_COUNTER_LOG() {
      const insertLogList = this.lodash
        .clonedeep(this.getCounterSensor.all)
        .map(x => {
          return {
            lot_id: this.packageInfo.lot_id,
            product_id: this.member_product_list.find(y => y.sync_plc)
              .member_product_id,
            value: x.value,
            plc_sensor_detail_type_id: x.plc_sensor_detail_type_id,
            plc_sensor_id: x.plc_sensor_id,
          };
        });

      this.INSERT_WITH_PAYLOAD(
        'INSERT_COUNTER_LOG',
        { data_list: insertLogList },
        '계수기 점유',
      );
    },
    async UPDATE_COUNTER_LOG() {
      await this.$store
        .dispatch('FETCH_COUNTER_LOG_NOW')
        .then(data_list => {
          if (data_list.length < 1) {
            this.openOneButtonModal('오류', '연동 종료 중 오류발생');
          } else {
            let temp = this.lodash.clonedeep(data_list);
            console.log('temp', temp);
            console.log('this.getCounterSensor', this.getCounterSensor);
            temp = temp.map(x => {
              const nowVal = this.getCounterSensor.all.find(
                y => y != undefined && y.plc_sensor_id == x.plc_sensor_id,
              );

              if (nowVal != undefined) {
                return {
                  id: x.id,
                  value: nowVal.value,
                };
              } else {
                return {
                  id: x.id,
                  value: 0,
                };
              }
            });
            this.UPDATE_WITH_PAYLOAD('UPDATE_COUNTER_LOG', { data_list: temp });
          }
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('오류', '연동 종료 중 오류발생');
        });
    },
    async FETCH_SENSOR_LOG_LSIT_NOW_CHECK() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION')
        .then(sensors => {
          this.sensor_list = sensors;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async FETCH_SENSOR_LOG_LSIT_NOW() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION')
        .then(sensors => {
          this.sensor_list = sensors;
          this.changeRowValue();
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeRowValue() {
      if (this.getCounterSensor.all.length > 0) {
        const targetMember = this.member_product_list.find(x => x.sync_plc);
        if (targetMember != undefined) {
          if (this.getCounterSensor.pass_counter.length > 0) {
            if (
              this.resetTime == null ||
              new Date(this.getCounterSensor.pass_counter[0].create_time) >
                this.resetTime
            ) {
              const sumPassQuantity = this.lodash
                .clonedeep(this.getCounterSensor.pass_counter)
                .map(x => x.value)
                .reduce((a, b) => this.$decimalAdd(a, b), 0);
              targetMember.pass_quantity = sumPassQuantity;
              this.resetTime = null;
            } else {
              targetMember.pass_quantity = 0;
            }
          }
          if (this.getCounterSensor.fail_counter.length > 0) {
            if (
              this.resetTime == null ||
              new Date(this.getCounterSensor.fail_counter[0].create_time) >
                this.resetTime
            ) {
              const sumFailQuantity = this.lodash
                .clonedeep(this.getCounterSensor.fail_counter)
                .map(x => x.value)
                .reduce((a, b) => this.$decimalAdd(a, b), 0);
              targetMember.fail_quantity = sumFailQuantity;
              this.resetTime = null;
            } else {
              targetMember.fail_quantity = 0;
            }
          }
        }
      }
    },
  },
  watch: {
    async timer(newValue) {
      if (newValue == 0) {
        await this.FETCH_SENSOR_LOG_LSIT_NOW();
        this.timer = 10;
      }
    },
  },

  async created() {
    this.showSpinner();
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', '센서');
    }
    if (this.process.length < 0) {
      await this.FETCH_NO_SPINNER('FETCH_PROCESS');
    }
    if (this.machine.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_MACHINE');
    }
    if (this.boxs.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_BOX', '박스');
    }
    if (this.units.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_UNIT', '단위');
    }

    if (this.target_product.product_group_yn) {
      const target_lot = this.lots.find(x => x.id == this.lot_id);
      const lot_detail = this.lodash.clonedeep(target_lot.lot_detail);
      this.member_product_list = this.target_product.product_group_list.map(
        x => {
          const found_lot_detail = lot_detail.find(
            y => y.member_product_id == x.member_product_id,
          );
          x.detail_quantity =
            found_lot_detail != undefined
              ? this.$makeComma(found_lot_detail.quantity)
              : '0';
          x.pass_quantity = 0;
          x.fail_quantity = 0;
          x.sync_plc = false;
          return x;
        },
      );
    } else {
      this.member_product_list.push({
        id: 999999,
        member_product_id: this.target_product.id,
        pass_quantity: 0,
        fail_quantity: 0,
        quantity: Number(localStorage.getItem('saupKey')) == 8 ? 0 : 1,
        sync_plc: false,
        detail_quantity: '0',
        resource_type_id: this.target_product.resource_type_id,
      });
      if (
        this.systemCompany.use_crushed_yn &&
        this.target_product.crushed_product_yn
      ) {
        const findCrushedProduct = this.lodash
          .clonedeep(this.products)
          .find(x => x.resource_type_id == 6);

        this.member_product_list.push({
          id: 999998,
          member_product_id: findCrushedProduct.id,
          pass_quantity: 0,
          fail_quantity: 0,
          quantity: Number(localStorage.getItem('saupKey')) == 8 ? 0 : 1,
          sync_plc: false,
          detail_quantity: '0',
          resource_type_id: 6,
        });
      }
    }

    if (this.sensor_detail_type.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR_DETAIL_TYPE', '센서 유형');
    }

    const sales_id = this.lots.find(x => x.id == this.packageInfo.lot_id)
      .sales_id;
    if (sales_id != undefined && sales_id != null) {
      this.$store.dispatch('FETCH_SALES_ONE', sales_id).then(response => {
        const product_list = response.data.data.product_list;
        product_list.forEach(el => {
          console.log(el);
          const hit = this.member_product_list.find(
            x => x.member_product_id == el.product_id,
          );

          console.log(hit);
          console.log(this.member_product_list);
          if (
            hit != undefined &&
            this.findInfoFromId(this.products, hit.group_product_id)
              .product_group_type_id == 1
          ) {
            //해
            if (el.box_unit_id != null) {
              const Box = this.findInfoFromId(this.boxs, el.box_unit_id);
              if (Box != undefined) {
                hit.pass_quantity = this.$decimalMul(
                  this.$makeNumber(el.quantity),
                  this.$makeNumber(Box.quantity),
                );
              } else {
                hit.pass_quantity = el.quantity;
              }
            } else {
              hit.pass_quantity = el.quantity;
            }
          }
        });
      });
    }
    await this.FETCH_SENSOR_LOG_LSIT_NOW_CHECK();
    this.hideSpinner();
  },
};
</script>

<style lang="scss" scoped></style>
